// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

import orderBy from 'lodash/orderBy';

// https://stackoverflow.com/a/30106551/5919446  base64 convert to utf8
// simple atob() can only convert base64 to ASCII
/**
 * convert base64 to utf-8
 * @param {*} str
 */
export function b2u(str) {
  return decodeURIComponent(escape(atob(str)));
}

/**
 * decode a base64 string with prefix `base64:xxxxxxx`
 * @param {*} str
 */
export function decodeBase64(str) {
  if (typeof str !== 'string') {
    return str;
  }
  if (str.startsWith('base64:')) {
    str = str.replace(/^base64:/, '');
    // need to try catch as there's input may result in URI malformed error
    try {
      str = b2u(str);
    } catch (error) {
      str = atob(str);
    }
    return str;
  }
  return str;
}

/**
 * order an object's key by the corresponding value
 * @param {*} obj
 */
export function getOrderedKeys(obj) {
  let keys = Object.keys(obj);
  let pairs = keys.map(k => ({
    key: k,
    value: obj[k],
  }));
  pairs = orderBy(pairs, 'value', 'desc');
  return pairs.map(p => p.key);
}
