// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

import {svg} from './svg';

export const samples = [
  {
    color: 1,
    buttonText: 'Pie Chart',
    sampleData: {
      'type': 'pie',
      'title': 'Browser market shares in January, 2018',
      'subtitle': 'here is a subtitle',
      'series': {
        'name': 'Brands',
        'data': {
          'Chrome': 61.41,
          'Internet Explorer': 11.84,
          'Firefox': 19.85,
          'Edge': 4.67,
          'Safari': 4.18,
          'Sogou Explorer': 1.64,
          'Opera': 1.6,
          'QQ': 1.2,
          'Other': 2.61,
        },
      },
    },
  },
  {
    color: 1,
    buttonText: 'Pie Chart (custom colors & tooltip suffix)',
    sampleData: {
      'type': 'pie',
      'title': 'Browser market shares in January, 2018',
      'subtitle': 'here is a subtitle',
      'colors': ['#E74C3C', '#F1C40F', '#9B59B6', 'rgb(255, 159, 64)', 'rgb(52, 183, 117)'],
      'series': {
        'name': 'Brands',
        'valuePrefix': 'like',
        'valueSuffix': 'Percent',
        'data': {
          'Chrome': {
            'value': 61.41,
            'suffix': 'suffix1',
          },
          'Internet Explorer': {
            'value': 11.84,
            'suffix': 'suffix2<br/>multiple line can add br by hand<br/> like this one',
          },
          'Firefox': {
            'value': 19.85,
            'suffix': 'suffix3',
          },
          'Edge': {
            'value': 4.67,
            'suffix': 'suffix4',
          },
          'Safari': {
            'value': 4.18,
            'suffix': 'suffix5',
          },
          'Sogou Explorer': {
            'value': 1.64,
            'suffix': 'suffix6',
          },
          'Opera': {
            'value': 1.6,
            'suffix': 'suffix7',
          },
          'QQ': {
            'value': 1.2,
            'suffix': 'suffix8',
          },
          'Other': {
            'value': 2.61,
            'suffix': 'suffix9',
          },
        },
      },
    },
  },
  {
    color: 1,
    buttonText: 'Pie Chart (data transform)',
    doc: 'valueUnit can be B/KB/MB/GB (size related) or ns/us/ms/s (duration related)',
    sampleData: {
      'type': 'pie',
      'title': 'Virtual Memory Usage Breakdown',
      'series': {
        'name': 'Size',
        'valueUnit': 'B', // can be B/KB/MB/GB
        'data': {
          'Resident Memory': 132984832,
          'Other': 56516608,
          'Swapped Memory': 233948,
        },
      },
    },
  },
  {
    color: 2,
    buttonText: 'Bar Chart',
    sampleData: {
      'type': 'bar',
      'title': 'Browser market shares in January, 2018',
      'subtitle': 'here is a subtitle',
      'colors': ['rgb(54, 162, 235)'],
      'height': 350,
      'width': 600,
      'series': {
        'name': 'Brands',
        'data': {
          'Chrome': 61.41,
          'Internet Explorer': 11.84,
          'Firefox': 19.85,
          'Edge': 4.67,
          'Safari': 4.18,
          'Sogou Explorer': 1.64,
          'Opera': 1.6,
          'QQ': 1.2,
          'Other': 2.61,
        },
      },
    },
  },
  {
    color: 2,
    buttonText: 'Bar Chart (with unit)',
    sampleData: {
      'type': 'bar',
      'title': 'Virtual Memory Usage Breakdown',
      'subtitle': 'here is a subtitle',
      'valueUnit': 'B',
      'height': 350,
      'width': 600,
      'series': {
        'name': 'Size',
        'data': {
          'Resident Memory': 132984832,
          'Other': 56516608,
          'Swapped Memory': 233948,
        },
      },
    },
  },
  {
    color: 2,
    buttonText: 'Bar Chart (multiple series)',
    sampleData: {
      'type': 'bar',
      'title': 'Browser market shares',
      'subtitle': 'here is a subtitle',
      'colors': ['#F1C40F', '#9B59B6', 'rgb(255, 159, 64)'],
      'width': 700,
      'categories': ['Chrome', 'Internet Explorer', 'Firefox', 'Edge', 'Safari', 'Sogou Explorer', 'Opera', 'QQ', 'Other'],
      'series': [{
        'name': '2017',
        'data': [55.45, 16.38, 18.32, 11.7, 5.32, 2.12, 1.28, 1.02, 3.41],
      },
      {
        'name': '2018',
        'data': [61.41, 11.84, 19.85, 4.67, 4.18, 1.64, 1.62, 1.2, 2.61],
      },
      {
        'name': '2019',
        'data': [64.31, 7.43, 21.67, 3.74, 3.83, 1.28, 1.45, 1.22, 2.39],
      },
      ],
    },
  },
  {
    color: 2,
    buttonText: 'Bar Chart (multiple series with unit)',
    sampleData: {
      'type': 'bar',
      'title': 'Virtual Memory Usage Breakdown',
      'subtitle': 'here is a subtitle',
      'colors': ['#F1C40F', '#9B59B6', 'rgb(255, 159, 64)'],
      'valueUnit': 'B',
      'categories': ['Resident Memory', 'Other', 'Swapped Memory'],
      'series': [{
        'name': '2017',
        'data': [132984832, 56516608, 233948],
      },
      {
        'name': '2018',
        'data': [154598309, 45939023, 144389],
      },
      {
        'name': '2019',
        'data': [349850398, 25385032, 304950],
      },
      ],
    },
  },
  {
    color: 2,
    buttonText: 'Stacked Bar Chart',
    doc: 'type="stacked-bar", others are the same as normal multi-series bar chart',
    sampleData: {
      'type': 'stacked-bar',
      'title': 'Browser market shares',
      'subtitle': 'here is a subtitle',
      'categories': ['Chrome', 'Internet Explorer', 'Firefox', 'Edge', 'Safari', 'Sogou Explorer', 'Opera', 'QQ', 'Other'],
      'series': [{
        'name': '2017',
        'data': [55.45, 16.38, 18.32, 11.7, 5.32, 2.12, 1.28, 1.02, 3.41],
      },
      {
        'name': '2018',
        'data': [61.41, 11.84, 19.85, 4.67, 4.18, 1.64, 1.62, 1.2, 2.61],
      },
      {
        'name': '2019',
        'data': [64.31, 7.43, 21.67, 3.74, 3.83, 1.28, 1.45, 1.22, 2.39],
      },
      ],
    },
  },
  {
    color: 2,
    buttonText: 'Column Chart',
    doc: 'type="column", vertical bar chart',
    sampleData: {
      'type': 'column',
      'title': 'Browser market shares in January, 2018',
      'subtitle': 'here is a subtitle',
      'colors': ['rgb(54, 162, 235)'],
      'height': 350,
      'width': 800,
      valueSuffix: '%',
      'series': {
        'name': 'Brands',
        'data': {
          'Chrome': 61.41,
          'Internet Explorer': 11.84,
          'Firefox': 19.85,
          'Edge': 4.67,
          'Safari': 4.18,
          'Sogou Explorer': 1.64,
          'Opera': 1.6,
          'QQ': 1.2,
          'Other': 2.61,
        },
      },
    },
  },
  {
    color: 2,
    buttonText: 'Distribution Bar Chart',
    doc: 'type="dist-bar", series.data keys are the ranges endpoint',
    sampleData: {
      'type': 'dist-bar',
      'title': 'Free Chunk Size Distribution',
      'height': 600,
      'series': {
        'name': 'Count',
        'categoryUnit': 'B', // simlimar to `valueUnit`, can be B/KB/MB/GB, will transform range values
        'categoryTitle': 'Chunk Size',
        'data': {
          '8': 0, // this means range [8, 16)
          '16': 0, // => [16, 32)
          '32': 28, // => [32, 64)
          '64': 13,
          '128': 17,
          '256': 50,
          '512': 111,
          '1024': 39,
          '2048': 10,
          '4096': 15,
          '8192': 5,
          '16384': 0,
          '32768': 0,
          '131072': 0,
          '262144': 0,
          '524288': 1,
          '1048576': 0,
          '2097152': 0,
        },
      },
    },
  },
  {
    color: 3,
    buttonText: 'Line Chart (timestamps with mark lines)',
    doc: 'by default x-axis are timestamps',
    sampleData: {
      'type': 'line',
      'title': 'Line chart example (default x-axis timestamp with mark lines)',
      'subtitle': 'here is a subtitle',
      valueSuffix: '%',
      'timestamps': [1597731660000, 1597731720000, 1597731780000, 1597731840000, 1597731900000, 1597731960000, 1597732021000, 1597732080000, 1597732140000, 1597732200000, 1597732261000, 1597732320000, 1597732380000, 1597732440000, 1597732500000, 1597732560000, 1597732620000, 1597732680000, 1597732740000, 1597732800000, 1597732860000, 1597732920000, 1597732980000, 1597733040000, 1597733101000, 1597733160000, 1597733220000, 1597733280000, 1597733341000, 1597733400000, 1597733461000, 1597733520000, 1597733580000, 1597733640000, 1597733700000, 1597733761000, 1597733820000, 1597733881000, 1597733940000, 1597734000000, 1597734060000, 1597734120000, 1597734180000, 1597734241000, 1597734300000, 1597734360000, 1597734421000, 1597734480000, 1597734540000, 1597734600000, 1597734660000, 1597734720000, 1597734780000, 1597734840000, 1597734900000, 1597734961000, 1597735020000, 1597735080000, 1597735140000, 1597735200000],
      'series': [
        {
          'name': 'Series 1',
          'data': [0, 0, 0.08, 0.2, 0.07, 0.03, 0.01, 0, 0, 0, 0, 0.09, 0.03, 0.05, 0.02, 0.01, 0.08, 0.03, 0.01, 0, 0.04, 0.01, 0, 0.33, 0.12, 0.05, 0.02, 0.04, 0.01, 0.01, 0, 0, 0.05, 0.14, 0.05, 0.02, 0.01, 0, 0, 0, 0, 0.12, 0.04, 0.06, 0.02, 0.01, 0, 0.03, 0.01, 0, 0, 0, 0, 0.27, 0.13, 0.05, 0.02, 0.01, 0, 0],
        },
        {
          'name': 'Series 2',
          'data': [0.04, 0.09, 0.07, 0.06, 0.05, 0.04, 0.03, 0.03, 0.02, 0.05, 0.04, 0.05, 0.04, 0.03, 0.04, 0.04, 0.03, 0.03, 0.04, 0.03, 0.02, 0.11, 0.09, 0.07, 0.06, 0.07, 0.05, 0.04, 0.04, 0.03, 0.04, 0.08, 0.06, 0.05, 0.04, 0.04, 0.03, 0.02, 0.02, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.04, 0.03, 0.03, 0.02, 0.01, 0.01, 0.09, 0.09, 0.07, 0.06, 0.05, 0.04, 0.03, 0.03, 0.02],
        },
        {
          'name': 'Series 3',
          'data': [0.07, 0.06, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.07, 0.07, 0.06, 0.06, 0.06, 0.05, 0.05, 0.05, 0.05, 0.05, 0.06, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.07, 0.07, 0.06, 0.06, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
        },
      ],
      xAxis: {
        plotLines: [
          {
            color: 'orange',
            width: 2,
            value: 1597731750000,
            label: {
              text: 'xAxis label line',
            },
          },
        ],
      },
      yAxis: {
        plotLines: [
          {
            color: 'blue',
            width: 2,
            value: 0.2,
            label: {
              text: 'yAxis label line',
              align: 'right',
            },
          },
        ],
      },
    },
  },
  {
    color: 3,
    buttonText: 'Line Chart (categories)',
    doc: 'define categories like bar/pie chart',
    sampleData: {
      'type': 'line',
      'title': 'Line chart example (customized x-axis categories)',
      'subtitle': 'here is a subtitle',
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      categoryTitle: 'Month',
      valueTitle: 'Count',
      'series': [
        {
          'name': 'Tokyo',
          'data': [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6],
        },
        {
          'name': 'London',
          'data': [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8],
        },
      ],
    },
  },
  {
    color: 4,
    buttonText: 'Gantt Chart',
    sampleData: {
      'type': 'gantt',
      'title': 'Gantt chart sample',
      'subtitle': 'here is a subtitle',
      'categories': ['Tech', 'Marketing', 'Sales'],
      timeformat: '%H:%M:%S.%L',
      'series': [{
        'name': 'Project 1',
        'data': [
          [
            {
              'start': 1602063035369,
              'end': 1602667835369,
              'name': 'Prototype',
              'id': 'prototype',
              'color': 'red',
            },
            {
              'start': 1602840635369,
              'end': 1603186235369,
              'name': 'Testing',
              'dependency': 'prototype',
            },
          ],
          [
            {
              'start': 1602667835369,
              'end': 1602927035369,
              'name': 'Product pages',
              'color': 'yellow',
            },
            {
              'start': 1603013435369,
              'end': 1603099835369,
              'name': 'Newsletter',
            },
          ],
          [
            {
              'start': 1603013435369,
              'end': 1603186235369,
              'name': 'Licensing',
              'id': 'testing',
            },
            {
              'start': 1603229435369,
              'end': 1603315835369,
              'name': 'Publish',
              'dependency': 'testing',
            },
          ],
        ],
      }],
    },
  },
  {
    color: 4,
    buttonText: 'Gantt Chart (subtask)',
    sampleData: {
      'type': 'gantt',
      'title': 'Gantt chart sample - subtask',
      'subtitle': 'here is a subtitle',
      timeformat: '%H:%M:%S.%L',
      'series': [{
        'name': 'Project 1',
        data: [
          [
            {
              'name': 'Planning',
              'id': 'planning',
              'start': 1606793039714,
              'end': 1608521039714,
            },
          ],
          [
            {
              name: 'Requirements',
              id: 'requirements',
              parent: 'planning',
              start: 1606793039714,
              end: 1607225039714,
            },
          ],
          [
            {
              name: 'Design',
              id: 'design',
              parent: 'planning',
              start: 1607052239714,
              end: 1608521039714,
            },
          ],
          [
            {
              name: 'Layout',
              id: 'layout',
              parent: 'design',
              'start': 1607052239714,
              'end': 1607657039714,
            },
          ],
          [
            {
              name: 'Graphics',
              parent: 'design',
              'start': 1607657039714,
              'end': 1608521039714,
            },
          ],
          [
            {
              name: 'Develop',
              id: 'develop',
              'start': 1607225039714,
              'end': 1609385039714,
            },
          ],
          [
            {
              name: 'Create unit tests',
              id: 'unit_tests',
              parent: 'develop',
              'start': 1607225039714,
              'end': 1607484239714,
            },
          ],
          [
            {
              name: 'Implement',
              id: 'implement',
              parent: 'develop',
              'start': 1607484239714,
              'end': 1609385039714,
            },
          ],
        ],
      }],
    },
  },
  {
    color: 5,
    buttonText: 'Advanced Table',
    doc: '',
    sampleData: {
      type: 'advanced-table',
      title: 'YSQL Output',
      headers_meta: [ // eslint-disable-line camelcase
        'Int', 'Str',
        {type: 'Int', unit: 'ms'},
        {type: 'Num', unit: 'ms'},
        {type: 'Num', unit: 'ms'},
        {type: 'Num', unit: 'ms'},
        {type: 'Num', unit: 'ms'},
        'Bool',
        {type: 'Int', unit: ''},
        {type: 'Int', unit: 'B'},
      ],
      data: advData, /* global advData */
    },
  },
  {
    color: 5,
    buttonText: 'Plain Table (csv)',
    doc: 'take csv string as input',
    sampleData: {
      type: 'table',
      csv: 'tenant_name,agent_name,job_id,link\nabc,def,12345,abc.com\nabc,def,12345,abc.com\nabc,def,12345,abc.com\n',
    },
  },
  {
    color: 5,
    buttonText: 'Plain Table (data)',
    doc: 'type = "table" render simple plain table with data array',
    sampleData: {
      type: 'table',
      title: 'table can have title, basic style use bootstrap classes, can provide csv string or data arrays',
      data: [
        ['tenant_name', 'agent_name', 'job_id', 'cpu', ''],
        ['abc', 'agent_1', '3242341', '30%', {
          'name': 'some-tool-name',
          'param1': '1',
          'param2': '2',
        }],
        ['abc', 'agent_2', '3242341', '31%', {
          'name': 'some-tool-name',
          'param1': '2',
          'param2': '3',
        }],
        ['abc', 'agent_3', '3242341', '32%', {
          'name': 'some-tool-name',
          'param1': '1',
          'param2': '4',
        }],
        ['abc', 'agent_4', '3242341', '33%', {
          'name': 'some-tool-name',
          'param1': '2',
          'param2': '5',
        }],
        ['abc', 'agent_5', '3242341', '34%', {
          'name': 'some-tool-name',
          'param1': '1',
          'param2': '6',
        }],
      ],
    },
  },
  {
    color: 6,
    buttonText: 'Markdown text',
    doc: 'type = "text" render the markdown data',
    sampleData: {
      type: 'text',
      data: `This is just a sample. You can play around with your own text right here.

Markdown
-------------

...is really just ordinary text, *plain and simple*. How is it good for you?

- You just **type naturally**, and the result looks good.
- You **don't have to worry** about clicking formatting buttons.
  - Or fiddling with indentation. (Two spaces is all you need.)

To see what else you can do with Markdown (including **tables**, **images**, **numbered lists**, and more) take a look at the [Cheatsheet][1]. And then try it out by typing in this box!

&nbsp;

::: warning
under warning custom block container
*here be dragons*
can also use **markdown** inside
:::

&nbsp;

::: error
error custom block container
:::


[1]: https://github.com/adam-p/markdown-here/wiki/Markdown-Here-Cheatsheet`,
    },
  },
  {
    color: 6,
    buttonText: 'Flamegraph',
    doc: 'type = "flamegraph" render svg text string like in iframe',
    sampleData: {
      type: 'flamegraph',
      svg,
    },
  },
  {
    color: 6,
    buttonText: 'Large Heatmap',
    sampleData: {
      'type': 'heatmap',
      'title': 'Large heat map sample',
      'subtitle': 'here is a subtitle',
      'colorAxis': {
        stops: [
          [0, '#3060cf'],
          [0.5, '#fffbbc'],
          [0.9, '#c4463a'],
          [1, '#c4463a'],
        ],
        min: -15,
        max: 25,
        unit: '℃',
      },
      xAxis: {
        format: '%B',
      },
      yAxis: {
        suffix: ':00',
      },
      tooltip: {
        headerFormat: 'Temperature <br/>',
        xFormat: '%e %b',
        ySuffix: ':00',
        valueSuffix: '℃',
      },
      series: [{
        name: 'Temparature',
        data: {
          csv: heatmapCSV, /* global heatmapCSV */
        },
      }],
    },
  },
];
