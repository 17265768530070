// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

import pick from 'lodash/pick';
import isPlainObject from 'lodash/isPlainObject';
import {anchorRegex} from '@/util/const';

export default function getGanttOpts(json, {context, level}) {
  const jsonSeries = json.series.map(series => {
    const data = [];
    for (let i = 0; i < series.data.length; i++) {
      const sData = series.data[i];
      for (let j = 0; j < sData.length; j++) {
        const d = sData[j];
        d.y = i;
        data.push(d);
      }
    }

    series.data = data;
    return series;
  });

  let labelHasLink = false;

  const opt = {
    chart: {
      type: 'gantt',
    },
    plotOptions: {
      gantt: {
        animation: false, // Do not animate dependency connectors
        dataLabels: {
          enabled: true,
          filter: null, // remove the filter in metaOpt, must be null
          format: '{point.name}',
          style: {
            cursor: 'default',
            pointerEvents: 'none',
          },
        },
      },
    },
    tooltip: {
      xDateFormat: '%a %b %d, ' + (json.timeformat || '%H:%M'),
    },
    xAxis: {
      currentDateIndicator: true,
      type: 'datetime',
      labels: {
        format: '{value:%Y-%m-%d}',
      },
    },
    yAxis: {
      type: 'treegrid',
    },
    series: jsonSeries,
  };

  if (json.height) {
    opt.chart.height = Number(json.height);
  }

  if (json.categories && json.categories.length) {
    opt.yAxis = {
      type: 'category',
      labels: {},
      categories: json.categories.map(c => {
        const label = isPlainObject(c) ? c.name : c;
        if (!labelHasLink && anchorRegex.test(label)) {
          labelHasLink = true;
        }
        return label;
      }),
      min: 0,
      max: json.categories.length - 1,
    };
  }

  if (labelHasLink) {
    opt.yAxis.labels.useHTML = true;
  }

  if (json.categoryClick) {
    opt.yAxis.labels.events = {
      click: function() {
        // this.value = label text, this.pos = index of categories
        if (context && context.onClickChart) {
          const paramKeys = json.categoryClick.params;
          const cateParams = json.categories[this.pos];
          context.onClickChart({
            label: this.value,
            tool: json.categoryClick.name,
            params: pick(cateParams, paramKeys),
            level,
          });
        }
      },
    };
    opt.yAxis.labels.style = {
      cursor: 'pointer',
      fontWeight: 'bold',
    };
    opt.plotOptions.gantt.point = {
      events: {
        click: function(eve) {
          if (context && context.onClickChart) {
            const point = eve.point;
            const pointParams = json.categories[point.y];
            const paramKeys = json.categoryClick.params;
            context.onClickChart({
              label: point.yCategory,
              tool: json.categoryClick.name,
              params: {...pick(pointParams, paramKeys), ts1: point.x, ts2: point.x2},
              level,
            });
          }
        },
      },
    };
  }

  return opt;
}
