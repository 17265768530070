// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';
import {bytes, seconds} from '@/util/units';
import {defaultColors} from '@/util/colors';
import {anchorRegex} from '@/util/const';
import {decodeBase64} from '@/util/helper';

export default function getPieOpts(json, {isPreview, getLabel, context, level}) {
  let transformer = null, unit;
  if (json.series.valueUnit) {
    if (['B', 'KB', 'MB', 'GB'].indexOf(json.series.valueUnit) > -1) {
      transformer = (val, sourceUnit) => bytes(val, {sourceUnit});
    } else if (['ns', 'us', 'ms', 's'].indexOf(json.series.valueUnit) > -1) {
      transformer = seconds;
    }
    unit = json.series.valueUnit;
  }

  const opt = {
    colors: json.colors || defaultColors,
    chart: {
      type: 'pie',
      height: isPreview ? 200 : Number(json.height || 400),
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          style: {
            fontSize: isPreview ? '9px' : '11px',
          },
          formatter: function() {
            let value = transformer ? transformer(this.y, unit) : this.y;
            if (json.series.valuePrefix) {
              value = json.series.valuePrefix + value;
            }
            if (json.series.valueSuffix) {
              value += json.series.valueSuffix;
            }
            return '<b>' + this.point.name + '</b>: ' + value + ` (${this.percentage.toFixed(1)}%)`;
          },
        },
        tooltip: {
          pointFormatter: function() {
            let value = transformer ? transformer(this.y, unit) : this.y;
            if (json.series.valuePrefix) {
              value = json.series.valuePrefix + value;
            }
            if (json.series.valueSuffix) {
              value += json.series.valueSuffix;
            }
            return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${value}</b> (${this.percentage.toFixed(1)}%)<br/><br/>`;
          },
        },
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '10px',
        cursor: 'pointer',
        textOverflow: 'ellipsis',
      },
    },
  };

  const data = json.series.data;
  let labelHasLink = false;
  let valueOnly = true;
  const useLabelFunc = isFunction(getLabel);
  opt.series = [{
    name: useLabelFunc ? getLabel(json.series.name, json.kind) : decodeBase64(json.series.name),
    data: Object.keys(data || {}).map(k => {
      if (!labelHasLink && anchorRegex.test(k)) {
        labelHasLink = true;
      }
      if (typeof data[k] === 'number') {
        return {
          name: useLabelFunc ? getLabel(k, json.kind) : decodeBase64(k),
          y: data[k],
        };
      } else {
        valueOnly = false;
        return {
          name: useLabelFunc ? getLabel(k, json.kind) : decodeBase64(k),
          y: data[k].value,
        };
      }
    }),
  }];

  if (labelHasLink) {
    opt.plotOptions.pie.dataLabels.useHTML = true;
  }

  if (!valueOnly) {
    opt.plotOptions.pie.tooltip.pointFormatter = function() {
      return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y}</b> (${this.percentage.toFixed(1)}%)<br/> ${data[this.name].suffix}<br/>`;
    };
  }

  if (json.categoryClick && !valueOnly) {
    opt.plotOptions.pie.point = {
      events: {
        select: function() {
          if (this.y !== 0 && context && context.onClickChart) {
            const paramKeys = json.categoryClick.params;
            const cateParams = data[this.name];
            context.onClickChart({
              tool: json.categoryClick.name,
              params: pick(cateParams, paramKeys),
              level: level,
            });
          }
        },
      },
    };
  }

  return opt;
}
