// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

export default function getHeatmapOpts(json) {
  const opt = {
    chart: {
      type: 'heatmap',
    },
    boost: {
      useGPUTranslations: true,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        align: 'left',
        format: `{value:${json.xAxis && json.xAxis.format ? json.xAxis.format : '%B %e'}}`,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      startOnTick: false,
      endOnTick: false,
    },
  };

  if (json.yAxis && json.yAxis.suffix) {
    opt.yAxis.labels = {
      format: '{value}' + json.yAxis.suffix,
    };
  }

  opt.colorAxis = json.colorAxis;
  opt.colorAxis.startOnTick = false;
  opt.colorAxis.endOnTick = false;
  if (json.colorAxis.unit) {
    opt.colorAxis.labels = {
      format: '{value}' + json.colorAxis.unit,
    };
  }

  opt.data = json.series[0].data;
  opt.series = [{
    name: json.series[0].name,
    boostThreshold: 100,
    borderWidth: 0,
    nullColor: '#EFEFEF',
    colsize: 24 * 36e5, // one day
    tooltip: {
      headerFormat: json.tooltip.headerFormat || '',
      pointFormat: `{point.x:${json.tooltip.xFormat || '%e %b'}} {point.y}${json.tooltip.ySuffix || ''}: <b>{point.value} ${json.tooltip.valueSuffix || ''}</b>`,
    },
    turboThreshold: Number.MAX_VALUE, // #3404, remove after 4.0.5 release
  }];

  return opt;
}
