// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.
// all same options for basically all charts

export const commonOpt = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  boost: {
    useGPUTranslations: true,
    seriesThreshold: 50,
  },
  chart: {},
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG',
          'downloadCSV', 'viewData',
        ],
      },
    },
  },
  title: {
    text: '', // set in each chart
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        filter: { // only show data labels when value>0
          property: 'y',
          operator: '>',
          value: 0,
        },
      },
    },
  },
  yAxis: {
    type: 'linear',
    categories: null,
    min: 0,
    title: {
      text: '',
    },
  },
};
