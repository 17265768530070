// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

import pick from 'lodash/pick';
import isPlainObject from 'lodash/isPlainObject';
import findIndex from 'lodash/findIndex';
import isFunction from 'lodash/isFunction';
import {lineColors} from '@/util/colors';
import {decodeBase64} from '@/util/helper';

export default function getLineOpts(json, {isPreview, getLabel, context, level}) {
  const useLabelFunc = isFunction(getLabel);
  const opt = {
    colors: json.colors || lineColors,
    chart: {
      type: 'line',
      height: isPreview ? 200 : Number(json.height || 400),
      zoomType: 'xy',
    },
    plotOptions: {
      line: {
        allowPointSelect: true,
        connectNulls: true, // ignore null data point
        marker: {
          enabled: false,
          radius: 2,
        },
        states: {
          hover: {
            halo: {
              size: 8,
            },
          },
          select: {
            radius: 4,
          },
        },
        events: {
          legendItemClick: function() {
            // change default legend item click behaviour, show the series only when click
            const index = this.index; // current series' index
            const allVisible = this.chart.series.every(s => s.visible);
            let prevIndex;
            if (!allVisible) {
              prevIndex = findIndex(this.chart.series, {visible: true});
            }
            for (let i in this.chart.series) {
              // when all series visible or previous visible series not current click series
              if (allVisible || (prevIndex !== index)) {
                if (Number(i) === Number(index)) {
                  this.chart.series[i].show();
                } else {
                  this.chart.series[i].hide();
                }
              } else {
                this.chart.series[i].show();
              }
            }
            return false; // disable default toggle of series visibility
          },
        },
      },
    },
    // most of our line charts is based on time
    xAxis: {
      labels: {
        autoRotation: [-10],
      },
      crosshair: true,
    },
    yAxis: {
      labels: {
        format: '{value}' + (json.valueSuffix || ''),
      },
    },
    tooltip: {
      shared: true,
      borderWidth: 0,
      pointFormatter: function() {
        const hoverIdx = this.series.chart.hoverSeries ? this.series.chart.hoverSeries.index : null;
        if (this.series.index === hoverIdx) {
          return `<span style="color:${this.color}">●</span><b> ${this.series.name}: ${this.y}${json.valueSuffix || ''}</b><br/>`;
        } else {
          return `<span style="color:${this.color}">●</span> ${this.series.name}: ${this.y}${json.valueSuffix || ''}<br/>`;
        }
      },
    },
    legend: {
      maxHeight: 70,
    },
  };

  if (json.timestamps && json.timestamps.length) {
    opt.xAxis.type = 'datetime';
    opt.xAxis.labels.format = '{value:%l:%M %p}'; // show am/pm for time
    opt.series = json.series.map(series => {
      const data = series.data.map((d, idx) => ({
        x: json.timestamps[idx],
        y: isPlainObject(d) ? d.value : d,
      }));
      series.name = useLabelFunc ? getLabel(series.name, json.kind) : decodeBase64(series.name);

      return {
        ...series,
        data,
      };
    });
  } else if (json.categories && json.categories.length) {
    opt.xAxis.type = 'category';
    opt.xAxis.categories = json.categories.map(c => useLabelFunc ? getLabel(c, json.kind) : decodeBase64(c));
    opt.series = json.series.map(series => {
      series.name = useLabelFunc ? getLabel(series.name, json.kind) : decodeBase64(series.name);
      return series;
    });
  }

  if (json.categoryTitle) {
    opt.xAxis.title = {
      text: useLabelFunc ? getLabel(json.categoryTitle, json.kind) : decodeBase64(json.categoryTitle),
    };
  }

  if (json.valueTitle) {
    opt.yAxis.title = {
      text: useLabelFunc ? getLabel(json.valueTitle, json.kind) : decodeBase64(json.valueTitle),
    };
  }

  // draw x-axis mark lines
  if (json.xAxis && json.xAxis.plotLines) {
    opt.xAxis.plotLines = json.xAxis.plotLines;
  }

  // draw y-axis mark lines
  if (json.yAxis && json.yAxis.plotLines) {
    opt.yAxis.plotLines = json.yAxis.plotLines;
  }

  if (json.pointClick) {
    opt.plotOptions.line.point = {
      events: {
        select: function() {
          if (context && context.onClickChart) {
            const series = json.series[this.series.index];
            const origData = series.data[this.index];
            const paramKeys = json.pointClick.params;
            context.onClickChart({
              label: this.series.name + ' ' + this.x, // not sure what's the label
              tool: json.pointClick.name,
              params: {...pick(origData, paramKeys)},
              level,
            });
          }
        },
      },
    };
  }

  return opt;
}
