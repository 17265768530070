// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

import isNumber from 'lodash/isNumber';
import isNull from 'lodash/isNull';
import isInteger from 'lodash/isInteger';

/* can accept sourceUnit = <B> KB MB GB TB PB
 */
export function bytes(value, {
  unitOnly = false,
  sourceUnit = 'B',
  targetUnit = '',
  integer = false,
} = {}) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let startCount = units.indexOf(sourceUnit);
  const endCount = targetUnit ? units.indexOf(targetUnit) : null;

  // workaround if value is negative
  const isNegative = value < 0;
  value = Math.abs(value);

  if (startCount < 0 || (isNumber(endCount) && endCount < 0)) {
    throw Error('unit specified not found');
  } else if (startCount === 0 && isNull(endCount)) {
    while (value >= 1024 && startCount < 4) {
      value = Math.round(value) / 1024;
      startCount++;
    }
  } else if (isNumber(endCount) && endCount > startCount) {
    for (let index = startCount; index < endCount; index++) {
      value = Math.round(value) / 1024;
    }
  }
  const unitIdx = endCount || startCount;
  const unit = units[unitIdx];
  const precision = Number.isInteger(value) ? 0 : (value + '').split('.')[1].length;
  if (integer) {
    if (unitIdx >= 3 && precision > 2) {
      value = value.toFixed(1);
    } else {
      value = Math.round(value);
    }
  } else if (!Number.isInteger(value)) {
    // if floating number decimals over 2, use toFixed(2), keep 3.02,3.5,3
    if (precision > 2) {
      value = value.toFixed(2);
    }
  }
  if (unitOnly) {
    return unit;
  }
  const formatted = `${value}${unit}`;
  return isNegative ? ('-' + formatted) : formatted;
}

/* can accept sourceUnit = ns <us> ms s
 */
export function seconds(value, sourceUnit = 'us') {
  let unit = sourceUnit || 'us';

  const formatNumber = (value, negative) => {
    const num = isInteger(value) ? value : value.toFixed(3);
    return negative ? ('-' + num) : num;
  };

  let negative = false;
  if (value < 0) {
    negative = true;
    value = Math.abs(value);
  }

  if (unit === 'ns') {
    if (value > 1000) {
      value = value / 1000; // ns -> us
      unit = 'us';
    } else {
      return `${formatNumber(value, negative)} ${unit}`; // small ns
    }
  }
  if (unit === 'us') {
    if (value > 1000) {
      value = value / 1000; // us -> ms
      unit = 'ms';
    } else {
      return `${formatNumber(value, negative)} ${unit}`; // small us
    }
  }
  if (unit === 'ms') {
    if (value > 1000) {
      value = value / 1000; // ms -> s
      unit = 's';
    } else {
      return `${formatNumber(value, negative)} ${unit}`; // small ms
    }
  }
  if (unit === 's' && value > 60) {
    value = value / 60; // s -> min
    unit = 'min';
  }

  return `${formatNumber(value, negative)} ${unit}`;
}
