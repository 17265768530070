// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

export const defaultColors = [
  'rgb(255, 99, 132)', // pink
  'rgb(54, 162, 235)', // blue
  'rgb(255, 159, 64)', // orange
  'rgb(75, 192, 192)', // mint
  'rgb(255, 205, 86)', // yellow
  'rgb(153, 102, 255)', // purple
  'rgb(52, 183, 117)', // green
  '#f7a992', // skin
  '#004488', // dark blue
  '#f44336', // red
  '#87ceeb', // skyblue
  '#90ee90', // lightgreen
  '#ffef9f', // light yellow
  '#cdb5ff', // light purple
  '#5583a6', // shaded blue
];

export const barColors = [
  '#7cb5ec', // light blue
  '#ff6384', // pink
  '#90ee7e', // green
  '#f7a35c', // orange
  'rgb(153, 102, 255)', // purple
  'rgb(255, 205, 86)', // yellow
  '#f44336', // red
];

export const lineColors = [
  '#E74C3C', // red
  '#F1C40F', // yellow
  '#9B59B6', // purple
  '#1A8F65', // green
  '#ff91d5', // pink
  '#3498DB', // blue
  '#BBF4B9', // mint
  '#E67E22', // orange
  '#004488', // dark blue
  '#DAD5FF', // light purple
];
