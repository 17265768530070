// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

import pick from 'lodash/pick';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
import {bytes, seconds} from '@/util/units';
import {barColors} from '@/util/colors';
import {anchorRegex} from '@/util/const';
import {decodeBase64, getOrderedKeys} from '@/util/helper';

export default function getBarOpts(json, {isPreview, getLabel, context, level}) {
  const useLabelFunc = isFunction(getLabel);

  const opt = {
    colors: json.colors || barColors,
    chart: {
      type: json.type === 'column' ? 'column' : 'bar',
      width: isPreview ? 400 : null,
      height: isPreview ? 200 : Number(json.height || 500),
    },
    plotOptions: {
      bar: {
        boostThreshold: 2000,
        animation: false,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: isPreview ? '9px' : '11px',
          },
        },
      },
    },
    legend: {
      enabled: !isPreview,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    yAxis: {
      min: 0,
      softMax: 10,
      allowDecimals: false,
      title: {
        text: (useLabelFunc && json.valueTitle) ? getLabel(json.valueTitle, json.kind) : decodeBase64(json.valueTitle || ''),
        align: 'high',
      },
      labels: {
        overflow: 'justify',
        autoRotation: [-10],
      },
    },
    tooltip: {},
  };

  let transform = null, unit;
  if (json.valueUnit) {
    if (['B', 'KB', 'MB', 'GB'].indexOf(json.valueUnit) > -1) {
      transform = (val, sourceUnit) => bytes(val, {sourceUnit});
    } else if (['ns', 'us', 'ms', 's'].indexOf(json.valueUnit) > -1) {
      transform = seconds;
    }
    unit = json.valueUnit;
  }

  if (Array.isArray(json.series)) { // multiple series
    let labelHasLink = false;
    if (json.type === 'stacked-bar') {
      opt.plotOptions.bar.stacking = 'normal';
    }
    opt.xAxis = {
      categories: json.categories.map(c => {
        const label = isPlainObject(c) ? c.name : c;
        if (!labelHasLink && anchorRegex.test(label)) {
          labelHasLink = true;
        }
        return useLabelFunc ? getLabel(label, json.kind) : decodeBase64(label);
      }),
      labels: {
        align: json.type === 'column' ? 'center' : 'right',
      },
    };
    if (labelHasLink) {
      opt.xAxis.labels.useHTML = true;
    }
    opt.series = json.series.map(series => {
      series.name = useLabelFunc ? getLabel(series.name, json.kind) : decodeBase64(series.name);
      return series;
    });

    if (unit) {
      opt.plotOptions.bar.dataLabels.formatter = function() {
        let value = transform ? transform(this.y, unit) : this.y;
        return value;
      };
      opt.tooltip.pointFormatter = function() {
        let value = transform ? transform(this.y, unit) : this.y;
        return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${value}</b> <br/>`;
      };
      opt.yAxis.labels.formatter = function() {
        let value = transform ? transform(this.value, unit) : this.value;
        return value;
      };
    }

  } else {
    const data = json.series.data;
    let categories = Object.keys(data || {});
    let sortedKeys;

    if (json.type === 'dist-bar') { // distribution-like bar chart, key is number, the value range point
      sortedKeys = categories.map(Number);
      sortedKeys.sort((a, b) => a - b);
      if (json.series.categoryUnit) {
        if (['B', 'KB', 'MB', 'GB'].indexOf(json.series.categoryUnit) > -1) {
          transform = (val, sourceUnit) => bytes(val, {sourceUnit});
        } else if (['ns', 'us', 'ms', 's'].indexOf(json.series.categoryUnit) > -1) {
          transform = seconds;
        }
        unit = json.series.categoryUnit;
      }
      categories = sortedKeys.map((k, idx) => {
        if (idx < sortedKeys.length - 1) {
          return `[${transform ? transform(k, unit) : k}-${transform ? transform(sortedKeys[idx + 1], unit) : sortedKeys[idx + 1]})`;
        }
        return `${transform ? transform(k, unit) : k}+`;
      });
    } else {
      sortedKeys = getOrderedKeys(data);
      categories = sortedKeys.map(c => useLabelFunc ? getLabel(c, json.kind) : decodeBase64(c));

      if (unit) {
        opt.plotOptions.bar.dataLabels.formatter = function() {
          let value = transform ? transform(this.y, unit) : this.y;
          return value;
        };
        opt.tooltip.pointFormatter = function() {
          let value = transform ? transform(this.y, unit) : this.y;
          return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${value}</b> <br/>`;
        };
        opt.yAxis.labels.formatter = function() {
          let value = transform ? transform(this.value, unit) : this.value;
          return value;
        };
      }
    }

    opt.xAxis = {
      categories,
      labels: {
        align: json.type === 'column' ? 'center' : 'right',
      },
      title: {
        text: (useLabelFunc && json.categoryTitle) ? getLabel(json.categoryTitle, json.kind) : decodeBase64(json.categoryTitle || ''),
      },
    };

    opt.series = [{
      name: useLabelFunc ? getLabel(json.series.name, json.kind) : decodeBase64(json.series.name),
      data: sortedKeys.map(k => data[k]),
    }];
  }

  opt.yAxis.labels.format = '{value}' + (json.valueSuffix || '');
  opt.tooltip.valueSuffix = json.valueSuffix;

  // draw x-axis mark lines
  if (json.xAxis && json.xAxis.plotLines) {
    opt.xAxis.plotLines = json.xAxis.plotLines;
  }

  // draw y-axis mark lines
  if (json.yAxis && json.yAxis.plotLines) {
    opt.yAxis.plotLines = json.yAxis.plotLines;
  }

  if (json.categoryClick) {
    opt.xAxis.labels = {
      events: {
        click: function() {
          // this.value = label text, this.pos = index of categories
          if (context && context.onClickChart) {
            const paramKeys = json.categoryClick.params;
            const cateParams = json.categories[this.pos];
            context.onClickChart({
              label: this.value,
              tool: json.categoryClick.name,
              params: pick(cateParams, paramKeys),
              level,
            });
          }
        },
      },
      style: {
        cursor: 'pointer',
        fontWeight: 'bold',
      },
    };
  }

  return opt;
}
