// Copyright (C) 2020-2022 by OpenResty Inc. All rights reserved.

import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {commonOpt} from './common';
import getPieOpts from './pie';
import getBarOpts from './bar';
import getLineOpts from './line';
import getGanttOpts from './gantt';
import getHeatmapOpts from './heatmap';

export default function getChartOpts(json, params = {}) {
  json = cloneDeep(json);
  const opt = {...commonOpt};

  opt.title.text = json.title || params.defaultTitle || '';
  opt.subtitle = {
    text: json.subtitle || '',
  };
  if (json.width) {
    opt.chart.width = Number(json.width);
  }

  if (params.isPreview) {
    opt.title.style.fontSize = '12px';
    opt.exporting = {
      enabled: false,
    };
  }

  if (json.type === 'pie') {
    return merge({}, opt, getPieOpts(json, params));
  } else if (json.type === 'bar' || json.type === 'stacked-bar' || json.type === 'column' || json.type === 'dist-bar') {
    return merge({}, opt, getBarOpts(json, params));
  } else if (json.type === 'line') {
    return merge({}, opt, getLineOpts(json, params));
  } else if (json.type === 'gantt') {
    return merge({}, opt, getGanttOpts(json, params));
  } else if (json.type === 'heatmap') {
    return merge({}, opt, getHeatmapOpts(json, params));
  }
  return opt;
}
